<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('price_lists.price_list') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('price_lists.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('price_lists.notes') }}</label>
                <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('price_lists.notes')"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-lg-12 pt-8">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('price_lists.prices') }}</h6>
            <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="form-group">
            <div class="bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('price_lists.product') }}</span>
                      <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <th>{{ $t('price_lists.sale_price') }}</th>
                  <th class="text-center">{{ $t("actions") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(it, index) in data_items" :key="index">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="it.item" class="d-flex cursor-pointer">
                      <img :src="it.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="it.item.sku_code">{{ $t('items.sku_code') }}: {{ it.item.sku_code }}</p>
                        <p class="mb-1" v-if="it.item.name">{{ $t('items.name') }}: {{ it.item.name }}</p>
                        <p class="mb-1" v-if="it.item.sale_price">{{ $t('items.sale_price') }}: {{ it.item.sale_price }}</p>
                        <p class="mb-1" v-if="it.item.purchase_price">{{ $t('items.purchase_price') }}: {{ it.item.purchase_price }}</p>
                        <p class="mb-1" v-if="it.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ it.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('price_lists.item')">
                      <select name="" id="" v-model="it.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>

                      <multiselect v-model="it.item"
                                   :placeholder="$t('price_lists.item')"
                                   :label="it.f_by? it.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   @input="handlingSalePriceValue(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(it.f_by, $event)">
                      </multiselect>
                    </div>
                  </td>
                  <td>
                    <input type="number" v-model="it.sale_price" @input="zeroForNegative" class="form-control" :placeholder="$t('items.sale_price')"/>
                    <span v-if="validation && validation[`items.${index}.sale_price`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items.${index}.sale_price`][0] }}
                        </span>
                  </td>
                  <td class="text-center">
                    <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

      <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
        <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
      </b-modal>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemForm from "@/view/content/forms/ItemForm";

export default {
  name: "from-priceList",
  components: {'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'items/price-lists',
      mainRouteDependency: 'base/dependency',
      items: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        notes: null,
        is_active: true,


      },
      isEditing: false,
      validation: null,
      repeater_items: {id: null, item: null, sale_price: null},
      data_items: [],
    };
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.data.is_active = this.data.is_active ? '1' : '0';

      ApiService.post(this.mainRoute, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/price-lists');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      this.data.is_active = this.data.is_active ? '1' : '0';

      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/price-lists');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.image_url = response.data.data.image_url;
        this.data_items = response.data.data.items;
        if (response.data.data.items.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    addToRepeater() {
      this.data_items.unshift(this.repeater_items);
      this.repeater_items = {id: null, item: null, sale_price: null};
    },
    deleteFromRepeater(index) {
      if (this.data_items.length > 1)
        this.data_items.splice(index, 1);
    },
    handlingSalePriceValue(index) {
      let _sale_price = this.data_items[index].item.sale_price;
      this.data_items[index].sale_price = _sale_price;
    },

    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
          this.items_list[0].tax_2 = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },

    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    zeroForNegative() {
      this.data_items = this.data_items.map((row) => {
        if(String(row.sale_price).length > 12){
          row.sale_price = String(row.sale_price).slice(0, 12);
        }
        return row;

      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Prices_lists"), route: '/items/price-lists'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getItems();
    if (this.idEdit) {
      this.getData();
    } else {
      if (this.data_items.length <= 0) {
        this.addToRepeater();
      }
    }

  },
};
</script>


